import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Tab,
  Nav,
  Modal,
  Accordion,
  Image,
} from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
// Import Swiper React components
import "../directives/header.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import "../component/home.css";

import Cookies from "js-cookie";

import Faqnew from "./HomeComponent/Home/faq";

const Home = () => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Header />
      <div className="lighttheme bg-white">
        <section className="herobanner position-relative text-white overflow-hidden">
          <div className="banner_bg">
            <img src="assets/images/banner3.png" className="bannerbefore" />
          </div>
          <Container>
            <Row className="align-items-center">
              <Col lg={6}>
                <Fade left>
                  <div className="bannerContent ">
                    <h5 className="text-white">
                      Experience the Future with High Throughput & Earnings
                    </h5>
                    <h1 className="text-white">Build With Us</h1>
                    <p className="mt-4 text-white">
                      A new standard in blockchain performance with our Layer-1
                      solution designed not only for high transactions per
                      second (TPS) but also for maximizing your earning
                      potential.
                    </p>
                    <p className="text-white">
                      Achieve high transactions per second (TPS), ensuring
                      swift, secure, and efficient operations that cater to your
                      needs.
                    </p>
                    <Link>
                      <Button onClick={() => window.open(
                        'https://mrmintscan.io/',
                        '_blank' // <- This is what makes it open in a new window.
                      )} variant="main2" className="text-white px-5 mt-3">
                        Explorer
                      </Button>
                    </Link>
                  </div>
                </Fade>
              </Col>
              <Col lg={6} className="text-center">
                {/* <div className="bannerImage position-relative">
                  <video
                    width="100%"
                    height="100%"
                    muted
                    loop
                    playsInline
                    autoPlay
                  >
                    <source
                      src="assets/images/blockchain.mp4"
                      type="video/mp4"
                    />
                    <source
                      src="assets/images/blockchain.mp4"
                      type="video/ogg"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div> */}
              </Col>
            </Row>
          </Container>
        </section>

        <section className="ourmission bg-white position-relative">
          <div className="shape">


          </div>
          <Container className="">
            <Row className="align-items-center ">
              <Col lg={6} className=" position-relative">
                <Slide right>
                  <div className="stepmint-icon">
                    <img src="assets/images/tolerance.png" width={`100%`} />
                  </div>
                  {/* <div className="stepmint_tooltip"> */}
                  {/* <div className=""> */}
                  {/* <span className="mb-0">Step Count Meter</span>
                      <p className="mb-0">
                        <b>289927</b>
                      </p> */}
                  {/* </div> */}
                  {/* </div> */}
                </Slide>
              </Col>
              <Col lg={6} className="">
                <Slide right>
                  <h5 className="text-black">
                    Blockchain As a Service
                  </h5>
                  <h2 className=" mb-4">
                    <b>Tailored Blockchain for Enterprise</b>
                  </h2>
                  <p>
                    With Mr Mint Blockchain as a Service (BaaS), organizations
                    can seamlessly integrate blockchain technology into their
                    operations without the overhead of managing their
                    architecture.
                  </p>
                  <p>
                    We offer customizable solutions that improve efficiency,
                    transparency, and security, enabling businesses to innovate
                    and thrive in a decentralized world.
                  </p>
                  {/* <Button variant="main2" className="text-white px-5 mt-3">
                    Know More
                  </Button> */}
                </Slide>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="ourimpact position-relative" id="ourimpact" style={{ background: "#15013f!important" }}>
          <div className="bg-overlay">

          </div>
          <Container>
            <Row>
              <Col lg={12} className="">
                <Zoom>
                  <div className="weight-content text-white">
                    <div className="mb-5 text-center">
                      <h5>Our Impact</h5>
                      <h2 className="text-white fw-semibold">
                        Customized Solutions Tailored For You
                      </h2>
                    </div>
                    <div className="">
                      {/* <p className="text-white mb-3">
                        10,000 Steps will improve you overall health
                      </p> */}
                      <div className="Tabstyle px-0">
                        <Row>
                          <Col lg={12}>
                            <Tab.Container
                              id="left-tabs-example"
                              defaultActiveKey="gaming"
                            >
                              <Row>
                                <Col sm={12} className="mb-5">
                                  <Nav
                                    variant="pills"
                                    className=" justify-content-center"
                                  >
                                    <Nav.Item>
                                      <Nav.Link eventKey="gaming">
                                        Gaming
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="enterprise">
                                        Enterprise
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="govt">Govt</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="validation">
                                        Validation
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="developer">
                                        Developer
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Nav>
                                </Col>
                                <Col sm={12}>
                                  <Tab.Content>
                                    <Tab.Pane eventKey="gaming">
                                      <div class="d-md-flex justify-content-around align-items-center">
                                        <div class="feature-img">
                                          <img
                                            src="assets/images/gaming.png"
                                            alt=""
                                            class="img-fluid"
                                          />
                                        </div>
                                        <div class="feature-content">
                                          <h5 class="white blocktext-desin">
                                            Boost up the gaming experience with
                                            true asset ownership on our
                                            blockchain. Players can trade, sell,
                                            and compete fairly, creating a
                                            vibrant ecosystem where gaming meets
                                            decentralization.
                                          </h5>
                                        </div>
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="enterprise">
                                      <div class="d-md-flex justify-content-around align-items-center">
                                        <div class="feature-img">
                                          <img
                                            src="assets/images/enterprise.png"
                                            alt=""
                                            class="img-fluid"
                                          />
                                        </div>
                                        <div class="feature-content">
                                          <h5 class="white blocktext-desin">
                                            Streamline operations with our
                                            customizable blockchain platform. We
                                            deploy advanced smart contracts
                                            quickly and with precision to
                                            automate processes, reduce costs,
                                            and elevate collaboration among
                                            teams.
                                          </h5>
                                        </div>
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="govt">
                                      <div class="d-md-flex justify-content-around align-items-center">
                                        <div class="feature-img">
                                          <img
                                            src="assets/images/Governance.png"
                                            alt=""
                                            class="img-fluid"
                                          />
                                        </div>
                                        <div class="feature-content">
                                          <h5 class="white blocktext-desin">
                                            Empower public services with Mr
                                            Mint’s secure blockchain solutions.
                                            Enhance transparency in voting
                                            systems and identity management to
                                            build trust and accountability
                                            within governance.
                                          </h5>
                                        </div>
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="validation">
                                      <div class="d-md-flex justify-content-around align-items-center">
                                        <div class="feature-img">
                                          <img
                                            src="assets/images/Validation.png"
                                            alt=""
                                            class="img-fluid"
                                          />
                                        </div>
                                        <div class="feature-content">
                                          <h5 class="white blocktext-desin">
                                            Validators maintain our blockchain’s
                                            integrity, and as essential
                                            stakeholders, they will thrive as
                                            the ecosystem grows, earning
                                            substantial rewards for their
                                            efforts.
                                          </h5>
                                        </div>
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="developer">
                                      <div class="d-md-flex justify-content-around align-items-center">
                                        <div class="feature-img">
                                          <img
                                            src="assets/images/Developer.png"
                                            alt=""
                                            class="img-fluid"
                                          />
                                        </div>
                                        <div class="feature-content">
                                          <h5 class="white blocktext-desin">
                                            Our user-friendly tools and SDKs,
                                            along with dedicated support and
                                            training programs, enable developers
                                            to create powerful decentralized
                                            applications (dApps) effortlessly.
                                          </h5>
                                        </div>
                                      </div>
                                    </Tab.Pane>
                                  </Tab.Content>
                                </Col>
                              </Row>
                            </Tab.Container>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Zoom>
              </Col>
              {/* <Col lg={5}>
                <div className="weightloss-img">
                  <img src="assets/images/woman-stretching-leg.png" />
                </div>
              </Col> */}
            </Row>
          </Container>
          <div className="leftstar">
            <img src="assets/images/leftstar.png" />
          </div>
        </section>
        <section class="powerful-features" id="whyus">
          <Container>
            <Row>
              <Col xs={12}>
                <h5>
                  Unique Features
                </h5>
                <h2 class=" mb-md-5 mb-3 fw-semibold">Why Us</h2>
              </Col>
            </Row>
            <Row>
              <Col xl={3} lg={6} md={6} xs={12} className="mb-4">
                <Card>
                  <img
                    src="assets/images/img-powerful-feature4.png"
                    alt=""
                    class="img-fluid"
                  />
                  <Card.Body class="p-3 pt-0">
                    <h5 md={4} xs={12} class="dk-blue mt-3 mb-3">
                      DPoS (Delegated Proof of Stake)
                    </h5>
                    <p>
                      Our DPoS mechanism promotes decentralization and scalability,
                      allowing the community to elect validators, ensuring a
                      democratic governance model.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={3} lg={6} md={6} xs={12} className="mb-4">
                <Card>


                  <img
                    src="assets/images/img-powerful-feature1.png"
                    alt=""
                    class="img-fluid"
                  />
                  <Card.Body class="p-3 pt-0">
                    <h5 class="dk-blue mt-3 mb-3">Voting Architecture</h5>
                    <p>
                      Experience a secure and transparent voting system that
                      enhances decision-making processes, fostering community
                      involvement and trust.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={3} lg={6} md={6} xs={12} className="mb-4">
                <Card>
                  <img
                    src="assets/images/HighTPS.png"
                    alt=""
                    class="img-fluid"
                  />
                  <Card.Body class="p-3 pt-0">
                    <h5 class="dk-blue mt-3 mb-3">High TPS</h5>
                    <p>
                      Enjoy unparalleled transaction speeds, allowing for thousands
                      of transactions per second, ensuring that businesses and users
                      never face bottlenecks.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={3} lg={6} md={6} xs={12} className="mb-4">
                <Card>
                  <img
                    src="assets/images/Security.png"
                    alt=""
                    class="img-fluid"
                  />
                  <Card.Body class="p-3 pt-0">
                    <h5 class="dk-blue mt-3 mb-3">Security</h5>
                    <p>
                      Robust security measures are embedded in our architecture, with features like encryption and consensus algorithms especially designed to protect against threats and vulnerabilities.

                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="bg-light becomeValidator">
          <Container>
            <Row className="align-items-center">
              <Col lg={12} className="">
                <Zoom>
                  <div className="weight-content text-black">
                    <div className="mb-5 text-center">
                      <h2 className="text-black  fw-semibold">
                        How to become a validator
                      </h2>
                      {/* <small className="text-black">
                        You’ve probably heard that you should walk 10,000 steps
                        per day for fitness and weight loss.
                      </small> */}
                    </div>
                    <div className="">
                      <div className=" px-0">
                        <Row class="">
                          <Col lg={4} md={6} class="">
                            <div className="news-insights">

                              <Card>
                                <Card.Body class="p-4">
                                  <div>
                                    <div className="circleIcon">
                                      <img src="assets/images/icon/ReachOut.png" />
                                    </div>
                                    <h4 class="">Reach out</h4>
                                    <h6 class="">
                                      Are you ready to make a difference? Connect
                                      with us and become a part of our expanding
                                      network of validators. We’re excited to have
                                      you on board and are here to provide all the
                                      information you need to get started.
                                    </h6>
                                  </div>
                                </Card.Body>
                              </Card>

                            </div>
                          </Col>
                          <Col lg={4} md={6} class="">
                            <div className="news-insights">
                              <Card>
                                <Card.Body class="p-4">
                                  <div>
                                    <div className="circleIcon">
                                      <img src="assets/images/icon/Buildwithus.png" />
                                    </div>
                                    <h4 class="">Build with us</h4>
                                    <h6 class="">
                                      Partner with our dedicated team to establish
                                      your very own validator node. We’re
                                      committed to your success and will equip you
                                      with the tools, resources, and expert
                                      guidance to ensure a smooth setup and
                                      optimal performance.
                                    </h6>
                                  </div>
                                </Card.Body>
                              </Card>
                            </div>
                          </Col>
                          <Col lg={4} md={6} class="">
                            <div className="news-insights">
                              <Card>
                                <Card.Body class="p-4">

                                  <div>
                                  <div className="circleIcon">
                                      <img src="assets/images/icon/BeaValidator.png" />
                                    </div>
                                    <h4 class="">Be a Validator</h4>
                                    <h6 class="">
                                      Step into a vital role within our community
                                      as a validator. We will help maintain the
                                      integrity and trustworthiness of the Mr Mint
                                      Blockchain. You will be validating
                                      transactions in real time, earning enticing
                                      rewards for your contribution.
                                    </h6>
                                  </div>

                                </Card.Body>
                              </Card>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Zoom>
              </Col>
            </Row>
          </Container>
        </section>
        <section className=" becomeValidator">
          <Container>
            <Row className="align-items-center">
              <Col lg={12} className="">
                <Zoom>
                  <div className="weight-content text-black">
                    <div className="mb-5 text-center">
                      <h2 className="text-black  fw-semibold">
                      How to become a delegator 
                      </h2>
                      {/* <small className="text-black">
                        You’ve probably heard that you should walk 10,000 steps
                        per day for fitness and weight loss.
                      </small> */}
                    </div>
                    <div className="">
                      <div className=" px-0">
                        <Row class="">
                          <Col lg={4} md={6} class="">
                            <div className="news-insights">

                              <Card>
                                <Card.Body class="p-4">
                                  <div>
                                  <div className="circleIcon">
                                      <img src="assets/images/icon/ReachOut.png" />
                                    </div>
                                    <h4 class="">Reach out</h4>
                                    <h6 class="">
                                      Reach out to your preferred validators and be a part of our journey towards greatness. Establish a relationship with them to understand how your staking can make a difference in securing the network.
                                    </h6>
                                  </div>
                                </Card.Body>
                              </Card>

                            </div>
                          </Col>
                          <Col lg={4} md={6} class="">
                            <div className="news-insights">
                              <Card>
                                <Card.Body class="p-4">
                                  <div>
                                  <div className="circleIcon">
                                      <img src="assets/images/icon/Stackholders.png" />
                                    </div>
                                    <h4 class="">Become a Stakeholder</h4>
                                    <h6 class="">
                                      By collaborating with Validators, you can contribute your tokens and influence network governance. As a Delegator, your involvement is crucial to the success of the Mr Mint Blockchain, and your voice matters.

                                    </h6>
                                  </div>
                                </Card.Body>
                              </Card>
                            </div>
                          </Col>
                          <Col lg={4} md={6} class="">
                            <div className="news-insights">
                              <Card>
                                <Card.Body class="p-4">

                                  <div>
                                  <div className="circleIcon">
                                      <img src="assets/images/icon/EarnReward.png" />
                                    </div>
                                    <h4 class="">Earn Rewards</h4>
                                    <h6 class="">
                                      Once you delegate your tokens to a validator, now you can earn rewards based on their performance. Enjoy passive income while playing a vital role in supporting a decentralized ecosystem.
                                    </h6>
                                  </div>

                                </Card.Body>
                              </Card>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Zoom>
              </Col>
            </Row>
          </Container>
        </section>

        {/* <section class="discover-connection">
          <Container class="container">
            <Row>
              <Col xs={12}>
                <h6 class="text-black text-center">What's Trending</h6>
                <h2 class="text-black text-center fw-semibold">
                  Latest trend
                </h2>
                <div class="d-md-flex d-block connect-list pt-lg-5 pt-3">
                  <div class="card-box connection1 d-flex align-items-start flex-column col-md-4 px-lg-5 px-3 pt-5">
                    <span class="badge bg-light text-dark px-3 mb-md-4 mb-3 text-center mt-auto">
                      Unleash
                    </span>
                    <h3 class="text-white mb-3">Welcome to NuChain</h3>
                    <p class="text-white mb-0 pb-md-5 pb-3">
                      Unleash the power of Blockchain with NuChain: unmatched
                      speed (200,000 TPS!), scalable solutions, &amp;
                      user-friendly CDK. Build faster, join the community, and
                      transform your industry.
                    </p>
                  </div>
                  <div class="card-box connection2 d-flex align-items-start col-md-4 flex-column px-lg-5 px-3 pt-5">
                    <span class="badge bg-light text-dark px-3 mb-md-4 mb-3 text-center mt-auto">
                      Discover
                    </span>
                    <h3 class="text-white mb-3">Discover the NuChain</h3>
                    <p class="text-white mb-0 pb-md-5 pb-3">
                      NuChain empowers industries with scalable Blockchain
                      solutions for secure voting, faster banking, tokenization,
                      &amp; streamlined travel experiences.
                    </p>
                  </div>
                  <div class="card-box connection3 d-flex align-items-start col-md-4 flex-column px-lg-5 px-3 pt-5">
                    <span class="badge bg-light text-dark px-3 mb-md-4 mb-3 text-center mt-auto">
                      Explore
                    </span>
                    <h3 class="text-white mb-3">Explore the ecosystem</h3>
                    <p class="text-white mb-0 pb-md-5 pb-3">
                      Unleash your Blockchain vision on NuChain: Blazing speed
                      (200,000 TPS), customizable platform, &amp; thriving dev
                      community. Build faster, shape the future.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section> */}

        {/* <section className="getstarted bg-white p-0 pb-5">
          <Container className="bg-blue position-relative">
            <Row className="align-items-center">
              <Col lg={6} className="">
                <Slide right>
                  <div className="p-3">
                    <h2>Join Stepmint:</h2>
                    <h4 className="pt-2 pb-2">
                      <b>Your Guide to the New World of Wealthy Fitness.</b>
                    </h4>
                    <Button variant="main2" className="text-white px-5 md-md-3">
                      Subscribe
                    </Button>
                  </div>
                </Slide>
              </Col>
              <Col lg={6} className=" text-center">
                <div className="sport-girl">
                  <img src="assets/images/sportgirl.png" />
                </div>
              </Col>
            </Row>
          </Container>
        </section> */}
      </div>

      <Modal show={show} backdrop="static" className="Nftmodal" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="btn-rounded ">
          <a href={`${config.baseUrl}contactus`}>
            <Image src="assets/images/PopupImage.png" fluid />
          </a>
        </Modal.Body>
      </Modal>

      <Footer />
    </>
  );
};
export default Home;

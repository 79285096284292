import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Tab,
  Nav,
  Modal,
  Accordion,
  Image,
} from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
// Import Swiper React components
import "../directives/header.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import "../component/home.css";

import Cookies from "js-cookie";

import Faqnew from "./HomeComponent/Home/faq";

const Home = () => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Header />
      <div className="lighttheme bg-white">
        <div
          class="page-banner-area pb-150 position-relative z-1"
          style={{
            backgroundImage: "url(assets/images/banner/page-banner1.jpg)",
          }}
        >
          <div class="container">
            <div class="page-bannner-content text-white">
        
              <h1 class="mb-0"> About us </h1>
            </div>
          </div>
        </div>
        <section className="about position-relative text-white overflow-hidden">
          <div className="banner_bg">
            <img src="assets/images/banner3.png" className="bannerbefore" />
          </div>
          <Container>
            <Row className="align-items-center">
              <Col lg={12}>
                <Fade left>
                  <div className="bannerContent my-0">
                    {/* <h1>About us</h1> */}
                    <p className="">
                      At Mr Mint Blockchain, we are dedicated to pioneering a
                      decentralized future through cutting-edge technology. We
                      are leveraging the Delegated Proof of Stake (DPoS)
                      consensus mechanism, Mr Mint ensures a highly efficient
                      and democratic network.
                    </p>
                    <p className="mb-0">
                      This mechanism not only enhances transaction speeds and
                      scalability but also incentivizes community engagement by
                      allowing stakeholders to participate in governance and
                      decision-making.
                    </p>
                   
                  </div>
                </Fade>
              </Col>
            </Row>
          </Container>
        </section>
      </div>

      <Footer />
    </>
  );
};
export default Home;

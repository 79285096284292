import React from 'react';
import { Container, Row, Col, Button, Accordion } from 'react-bootstrap';
import "../css/home.css"
import "../css/responsive.css"
import Header from '../../../directives/header';
import Footer from '../../../directives/footer';

const Faqnew = () => {
    return (
        <>
<section className=' pt-0'>
<Container>
    <Row>
        <Col lg={12}>

            <div className=''>
                <div className='side_bg'>
                    <img src='assets/home_assets/images/side_bg.png' />

                </div>
                <Container className='position-relative'>
                    <Row className='justify-content-center align-items-center'>

                        <Col lg={6}>

                            <div className='text-center position-relative'>
                                <h2 className='subheading text-uppercase faq-heading'>FAQ</h2>
                                <img src='assets/home_assets/images/run_man.png' height="701px" style={{ ObjectFit: "contain" }} className='runman' />

                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='faq-list'>
                                <Accordion defaultActiveKey={['0']} flush>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>What is StepMint.io?</Accordion.Header>
                                        <Accordion.Body>
                                            Stepmint is a revolutionary platform that transforms your daily steps into real crypto rewards, merging fitness and finance seamlessly using Web 3.0 technology.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>How do I get started?</Accordion.Header>
                                        <Accordion.Body>
                                            To get started, simply visit our website at https://stepmint.io/ and subscribe now for early bird access!
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                            Is the StepMint App free to use?</Accordion.Header>
                                        <Accordion.Body>
                                            No.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>What payment methods do you accept for premium plans?</Accordion.Header>
                                        <Accordion.Body>
                                            We accept MNT only. Our payment processing is secure, and your information is always safe. We accept MNT only. Our payment processing is secure, and your information is always safe.We accept MNT only. Our payment processing is secure, and your information is always safe.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                            How can I contact customer support?</Accordion.Header>
                                        <Accordion.Body>
                                            If you have any questions or need assistance, you can raise tickets. Our customer support team will get back to you.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                            Is my data safe with StepMint.io?</Accordion.Header>
                                        <Accordion.Body>
                                            If you have any questions or need assistance, you can raise tickets. Our customer support team will get back to you.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>
                                            Can I cancel my premium subscription?</Accordion.Header>
                                        <Accordion.Body>
                                            We have a No cancellation policy.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        </Col>
    </Row>
</Container>

</section>

</>
    )
}

export default Faqnew
import React, { Component, useEffect } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import config from "./config/config";
import "./App.css";
import Home from "./component/home";
import About from "./component/aboutus";


const RouterComponent = () => {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path={`${config.baseUrl}`} element={<Home />} />
          <Route path={`${config.baseUrl}about`} element={<About />}/>

        </Routes>
      </div>
    </BrowserRouter>
  );
};
export default RouterComponent;

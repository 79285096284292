import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import config from "../config/config";
import {
  Container,
  Nav,
  Modal,
  Navbar,
  NavDropdown,
  Offcanvas,
  Form,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../directives/header.css";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";


const Header = () => {
  const [islogin, setislogin] = useState(false);
  const [show, setShow] = useState(false);



 
  const handleShow = () => {
    window.open(
      'https://mrmintscan.io/',
      '_blank' // <- This is what makes it open in a new window.
    );
  };

 
  const navigate = useNavigate();
  const handleClose = () => {
    setShow(false);
  };
  const navigateToSignup = () => {
    setShow(false);
    setislogin(false);
    navigate(`${config.baseUrl}signup`);
  };

  return (
    <>
      <header className="pt-1 pb-1">
        <Toaster />
        <Container className="p-0">
          {["xl"].map((expand) => (
            <Navbar key={expand} expand={expand} className="">
              <Container fluid>
                <Navbar.Brand href="#">
                  <img src="assets/images/darklogo.png" alt="" width={`160px`} />
                </Navbar.Brand>
                <Navbar.Toggle
                  aria-controls={`offcanvasNavbar-expand-${expand}`}
                />
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="end"
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-${expand}`}
                    >
                      <img src="assets/images/darklogo.png" alt="" width={`160px`}/>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="justify-content-center flex-grow-1 pe-3">
                      <Nav.Link
                        href={`${config.baseUrl}about`}
                        className={``}
                      >
                        About us
                      </Nav.Link>
                      <Nav.Link
                        href={`#whyus`}
                        className={""}
                      >
                        Why Us 
                      </Nav.Link>
                      <Nav.Link
                        href={`#ourimpact`}
                        className={""}
                      >
                        Our Impact
                      </Nav.Link>
                      <Nav.Link
                        href={`${config.baseUrl}assets/images/MrmintBlockchainDocument.pdf`}
                        target="_blank"
                        className={""}
                      >
                        Documentation
                      </Nav.Link>
                    </Nav>
                    
                      <Button
                        onClick={() => handleShow()}
                        variant="main2"
                        className="text-white px-4"
                      >
                        Explorer
                      </Button>
                  
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          ))}
        </Container>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          className="Nftmodal"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="btn-rounded pt-2 pb-3">
            <Row className="justify-content-center">
              <Col lg={8}>
                <div className="text-center mb-3">
                  <Row className="align-items-center">
                    <Col lg={12} className="mt-0">
                      <button
                        className="btn btn-main2 text-white btn-rounded px-4 rounded-5"
                      >
                        {/* <img alt="" src="assets/images/metamask_icon.png" height="28px"></img> &nbsp; */}
                        Connect Wallet
                      </button>
                    </Col>
                    <Col lg={12} className="mt-2">
                      <span className=" btn-rounded px-4 rounded-5">or</span>
                    </Col>
                    <Col lg={12} className="mt-2">
                      <button
                        className="btn btn-main2 btn-sm text-white btn-rounded px-4 rounded-5"
                        onClick={navigateToSignup}
                      >
                        Signup
                      </button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </header>
    </>
  );
};
export default Header;
